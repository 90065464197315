import { pipe, toLower, startCase } from "lodash/fp"
import { version } from '../../package.json'
import UAParser from 'ua-parser-js'

export function formatPhone(value) {
  if (!value) return

  value = value.replace(/[^0-9]/g, "")

  if (value.length <= 7) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`
  }

  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
}

export function formatEnumConstant(constant) {
  // "AS_USUAL" => "As Usual"
  return pipe(toLower, startCase)(constant)
}

export const replaceSubdomain = (url, subdomain) => {
  const hostParts = url.host.split(".")
  hostParts[0] = subdomain

  return url.protocol + "//" + hostParts.join(".") + url.pathname
}

export const getAppVersion = () => version;

export const getFullAppVersion = () => {
  const ua = new UAParser().getResult()

  return `${version};${ua.os.name} ${ua.os.version};${ua.browser.name} ${ua.browser.version}`;
}
