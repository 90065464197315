import { useRouter } from "next/router"
import React, { useContext } from "react"
import { useCurrentUser } from "app/lib/CurrentUser"
import { useOrganization } from "app/common/Organization"
import { useUniversalCtx } from "app/lib/universal-context"
import getLifeRoutes from "./config/life"
import getReachRoutes from "./config/reach"

const Context = React.createContext()

export function getProductDomain(hostname) {
  let productDomain = hostname.split(".")[0]

  // We're only handling Life and Connect for now
  if(productDomain !== "connect")
    productDomain = "life"

  return {
    isReach: productDomain === "reach",
    isConnect: productDomain === "connect",
    isLife: productDomain === "life",
    productDomain: productDomain,
  }

  // return {
  //   isReach: productDomain === "reach",
  //   isConnect: productDomain === "connect",
  //   isLife: productDomain === "life",
  //   productDomain: productDomain,
  // }
}

export default function ProductProvider({ children }) {
  const emcOrganization = useOrganization()?.features?.emenuChoice

  const { isLife, isReach, isConnect, productDomain } = useUniversalCtx()
  const currentUser = useCurrentUser()

  const routes = isReach
    ? getReachRoutes({ currentUser })
    : getLifeRoutes({ currentUser, emcOrganization })

  return (
    <Context.Provider
      value={{ routes, isLife, isReach, isConnect, productDomain }}
    >
      {children}
    </Context.Provider>
  )
}

export const useProduct = () => useContext(Context)

export function usePageTitle() {
  const { routes } = useProduct()
  const router = useRouter()

  const findCurrentRoute = (route) => route.href === router?.pathname

  let pageTitle
  for (const route of routes) {
    if (route.items) {
      const found = route.items.find(findCurrentRoute)
      if (found) {
        pageTitle = found.label
        break
      }
    }
    if (findCurrentRoute(route)) {
      pageTitle = route.label
      break
    }
  }

  return pageTitle
}
