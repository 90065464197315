import { userTypes } from "app/lib/CurrentUser"

const getLifeRoutes = ({ currentUser, emcOrganization }) => {
  const showMyProgress = [userTypes.FAMILY_MEMBER, userTypes.DEFAULT].includes(
    currentUser.userType
  )
  return [
    {
      label: "Home",
      href: "/[organizationSlug]/home",
    },
    {
      label: "Messages",
      href: "/[organizationSlug]/messages",
    },
    {
      label: "Events",
      href: "/[organizationSlug]/events",
    },
    // {
    //   label: "Campus Life",
    //   href: "/[organizationSlug]/home",
    // },
    {
      label: "Dining",
      items: emcOrganization
        ? [
            {
              label: "Menu",
              href: "/[organizationSlug]/meals",
            },
          ]
        : [
            {
              label: "Menu",
              href: "/[organizationSlug]/meals",
            },
            {
              label: "Venues",
              href: "/[organizationSlug]/dining-venues",
            },
          ],
    },

    currentUser.userType !== userTypes.FAMILY_MEMBER && {
      label: "Connect",
      items: [
        {
          label: "Directory",
          href: "/[organizationSlug]/users",
        },
        {
          label: "Groups",
          href: "/[organizationSlug]/groups",
        },
      ],
    },
    {
      label: "Resources",
      items: [
        {
          label: "Services",
          href: "/[organizationSlug]/guide",
        },
        {
          label: "Documents",
          href: "/[organizationSlug]/documents",
        },
        {
          label: "Links",
          href: "/[organizationSlug]/web-views",
        },
        {
          label: "App Support",
          href: "https://wellzesta-app.zendesk.com",
        },
      ],
    },
    {
      label: "Well-being",
      items: [
        {
          label: "Health Coach",
          href: "/[organizationSlug]/videos",
        },
        {
          label: "Articles",
          href: "/[organizationSlug]/articles",
        },
      ],
    },
    showMyProgress && {
      label: "My Profile",
      items: [
        {
          label: "My Progress",
          href: "/[organizationSlug]/progress",
        },
        {
          label: "Edit My Profile",
          href: `/[organizationSlug]/users/${currentUser}/update`,
        },
      ],
    },
  ].filter(Boolean)
}

export default getLifeRoutes
